import "./LoadScreen.css";
import { useState } from "react";
import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue,
  useSpring
} from 'framer-motion';
export default function HomeScreen() {
  
  const animation = {
    animate: {
      width: [0,300,300],
      transition: {
        times: [0,0.8,1],
        duration: 2.5,
      }
    }
  };

  const opacity = {
    animate: {
      opacity: [1,1,0],
      transition: {
        times: [0,0.8,1],
        duration: 2.5,
      }
    }
  };

  return (
    <motion.div {...opacity}>
      <motion.div {...animation} initial={{width:0}} className="LoadScreen"/>
      <h1 className="LoadScreen__h1">S</h1>
    </motion.div>
  );
}

