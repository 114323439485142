import "./Button.css"
import { useInView } from "react-intersection-observer";
import { motion } from 'framer-motion';

const animation1 = {
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.5
    }
  }
};

const animation2 = {
  animate: {
    opacity: 0,
    transition: {
      duration: 1
    }
  }
};

const Button = ({text, onClick}) => {
  const { ref, inView } = useInView({threshold:1});
  return(
    <motion.div ref={ref} initial={{opacity:0}} {...inView ?animation1 : animation2} onClick={onClick} className="Button">
      <h2 className="Button__h2">{text}</h2>
    </motion.div>
  )
}

export default Button;