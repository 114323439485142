import emailjs from '@emailjs/browser';


async function SendEmail(email,text)  {
    emailjs.init("user_acGAKpTgBSLs4hCkUOg3T");
    let value;

    const templateParams = {
      name: email,
      message: text,
    };
 
    await emailjs.send("service_dam46gh","template_2tija8j", templateParams)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
       value = true;
    }, function(error) {
       console.log('FAILED...', error);
       value = false;
    });
    return value;
}

export default SendEmail;