import "./FooterScreen.css";
import { useState } from "react";
import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue,
  useSpring
} from 'framer-motion';
import Item from "../components/Item";
import { ReactComponent as Icon1 } from "../images/email-icon.svg"
import { ReactComponent as Icon2 } from "../images/github-icon-1.svg"
export default function HomeScreen() {
  const [wHeight, setWHeight] = useState(window.screen.height);
  const { scrollY } = useViewportScroll();
  const o1 = useTransform(scrollY, [wHeight * 0.5 + 100, wHeight * 0.5 + 101], [0,1]);

  const Box = ({onClick, image, h2, text}) => {
    return(
      <div onClick={onClick} className="ContactScreen__box">
        <Item image={image}/>
        <div className="ContactScreen__box-text">
          <h2 className="FooterScreen__box-h2">{h2}</h2>
          <p className="FooterScreen__box-p">{text}</p>
        </div>
      </div>
    )
  }
  
  return (
    <div className="FooterScreen">
      <motion.div style={{opacity:o1}} className="FooterScreen__container">
          <div className="FooterScreen__box-flex">
            <Box h2={"EMAIL"} text={"pavel@suchanek.dev"} image={<Icon1 className="ContactScreen__icon" />}/>
            <Box h2={"GIT"} text={"Pavel0123"} image={<Icon2 className="ContactScreen__icon" />} onClick={() => window.location.replace("https://github.com/Pavel0123/")}/>
          </div>
          <div className="FooterScreen__copyright">
            <p className="FooterScreen__p2">© 2022</p>
            <p className="FooterScreen__p1">Pavel Suchánek</p>
          </div>
      </motion.div>
    </div>
  );
}

