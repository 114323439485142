import "./Item.css"
import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue
} from 'framer-motion';

const Item = ({image ,text, onClick, style}) => {
  return(
    <motion.div onClick={onClick} style={style} className="Item">
      <div className="Item__image">
        {image}
      </div>
      <h2 className="Item__h2">{text}</h2>
    </motion.div>
  )
}

export default Item;