import "./AboutScreen.css"
import Button from "../components/Button";
import Card from "../components/Card";
import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue
} from 'framer-motion';
import { useEffect, useState } from "react";
import { ReactComponent as Border } from "../images/border.svg"
import { ReactComponent as Icon1 } from "../images/html-icon.svg"
import { ReactComponent as Icon2 } from "../images/css-icon.svg"
import { ReactComponent as Icon3 } from "../images/js-icon.svg"
import { ReactComponent as Icon4 } from "../images/java-icon.svg"
import { ReactComponent as Icon5 } from "../images/mysql-icon.svg"

import { ReactComponent as Icon6 } from "../images/horseFigure-icon.svg"
import { ReactComponent as Icon7 } from "../images/tree.svg"

export default function HomeScreen() {

  const Box = ({text}) => {
    const [selection, setSelection] = useState(1); 

    const animation1 = {
      animate: {
        opacity: 1,
        transition: {
          duration: 2,
        }
      }
    };
    const animation2 = {
      animate: {
        opacity: 0.5,
        transition: {
          duration: 2,
        }
      }
    };

    const animation3 = {
      animate: {
        height: 304,
        transition: {
          duration: 1,
          delay:0.5
        }
      }
    };


    const animation4 = {
      animate: {
        height: 236,
        transition: {
          duration: 1,
          delay:0.5
        }
      }
    };


    return(
      <div className="AboutScreen__box">
          <div className="AboutScreen__header">
            <div className="AboutScreen__header-flex">
              <motion.h1 onClick={() => setSelection(1)} initial={{opacity:0.5}} {...selection === 1 ? animation1 : animation2} className="AboutScreen__box-h1">KDO JSEM</motion.h1>
              <motion.h1 onClick={() => setSelection(2)} initial={{opacity:0.5}} {...selection === 2 ? animation1 : animation2} className="AboutScreen__box-h1">ZDATNOSTI</motion.h1>
            </div>
        </div>
        <BorderBox top/>
        <motion.div {...selection === 1 ? animation3 : animation4} className="AboutScreen__responsive">    
          <Content selection={selection === 1 ? 1 : 2}
          content1={<p className="AboutScreen__box-p">{text}</p>}
          content2={<Skills/>}
          />
        </motion.div>
        <BorderBox bottom/>
          <Content selection={selection === 1 ? 1 : 2}
          content1={<CV/>}
          content2={<Cards/>}
          />
      </div>
    )
  }

  const CV = () => {
    return(
      <a href={process.env.PUBLIC_URL + "/cv.pdf"} download={"Životopis"}>
        <Button text={"STÁHNOUT CV"}/>
      </a>
    )
 }

  const Cards = () => {
    return(
      <div className="AboutScreen__cards">
        <Card heading={"CHESS"} image={<Icon6 className="Card__icon1"/>} onClick={() => window.location.replace("https://brain-chess.web.app/")}/>
        <Card heading={"PAGE"} image={<Icon7 className="Card__icon2"/>} onClick={() => window.location.replace("https://old.suchanek.dev/")}/>
      </div>
    )
  }

  
  const BorderBox = ({top,bottom}) => {
    if(top) 
    return(
      <Border className="AboutScreen__border-top"/>
    )
    if(bottom) 
    return(
      <Border className="AboutScreen__border-bottom"/>
    )
    return(null)
  }
  

  const Content = ({selection,content1,content2}) => {
    const [selectionUpdated, setSelectionUpdated] = useState(1); 

    useEffect(() => {
      setTimeout(() => setSelectionUpdated(selection),1000)
    },[selection])

    const animation5 = {
      animate: {
        opacity: 1,
        transition: {
          duration: 1,
        }
      }
    };
    const animation6 = {
      animate: {
        opacity: 0,
        transition: {
          duration: 1,
        }
      }
    };

    if(selectionUpdated === 1)
    return(
      <motion.div {...selection === 1 ? animation5 : animation6}>
        {content1}
      </motion.div>
    )

    if(selectionUpdated === 2)
    return(
      <motion.div {...selection === 2 ? animation5 : animation6}>
        {content2}
      </motion.div>
    )

  }

  const Skills = ()  => {
  
    const Componet = () => {
      const [opacity, setOpacity] = useState(1);

      return (
        <motion.div className="SkillsScreen__box">
          <Leangues InView={opacity > 0.7 ? true : false}/>
        </motion.div>
      )
    } 
  
    const Leangues = ({InView}) => {
      return (
        <div>
          <div className="SkillsScreen__component-box"> 
            <Box InView={InView} value={<Icon1 className="SkillsScreen__icon"/>} text={"HTML"} skill={5} first={false}/>
            <Box InView={InView} value={<Icon2 className="SkillsScreen__icon"/>} text={"CSS"}  skill={5}/>
            <Box InView={InView} value={<Icon3 style={{width:24, height:24, marginLeft:3, marginRight:14}}  className="SkillsScreen__icon"/>} text={"JS"} skill={3}/>
            <Box InView={InView} value={<Icon4 style={{width:24, height:24 ,marginLeft:3, marginRight:12, borderRadius:5}} className="SkillsScreen__icon"/>} text={"JAVA"} skill={3}/>
            <Box InView={InView} value={<Icon5 style={{width:24, height:24, marginLeft:3, fill:"white"}} className="SkillsScreen__icon"/>} text={"SQL"} skill={2}/>
          </div> 
          <div style={{marginTop: -1}} className="SkillsScreen__component-box"> 
                <Box InView={InView} text={"Firebase"} skill={3} first={false}/>
                <Box InView={InView} text={"React"}  skill={3}/>
                <Box InView={InView}  className="SkillsScreen__icon" text={"Angular"} skill={2}/>
          </div> 
        </div>
      )
    } 
  
    const Box = ({value , text, skill , first, InView}) => {
      let array = [];
      for(let x = 0 ; x !== skill; x++) {
        array.push(<motion.div key={x} className="SkillsScreen__component-graf"></motion.div>)
      }
  
      return (
        <div className="SkillsScreen__component-box-flex"> 
          <div className="SkillsScreen__component-box-flex">
            <h2 className="SkillsScreen__h2">{text}</h2>
          </div>
          <div className="SkillsScreen__component-grafbox">{array}</div>
        </div>
      )
    } 

    return(
      <Componet/>
    )
  }



  return (
    <div className="AboutScreen">
      <div className="AboutScreen__container">
        <Box text={"Jsem 19 letý student žijící v Praze. V květnu letošního roku jsem úspěšně odmaturoval s vyznamenáním na střední odborné škole se zaměřením na IT. Programování se věnuji řadu let. Našel jsem se v něm a i můj maturitní projekt byl hodnocen jako jeden z nejlepších na škole. Programuji i ve svém volném čase a kromě vývoje mě zajímá i celkově informační technologie. Rád se učím novým věcem a nedělá mi problém pracovat v kolektivu. Považuji se za vysoce organizovaného člověka, který dělá věci včas a je spolehlivý. Snažím se sám řešit problémy a pracovat nezávisle. Pracoval jsem na několika větších školních projektech a mám pracovní zkušenosti v oboru jako Programátor / Tester. V osobním životě se aktivně věnuji sportu, posiluji a vzdělávám se. V říjnu letošního roku nastupuji na vysokou školu Unicorn University."}/>
      </div>
    </div>
  );
}

