import "./HomeScreen.css"
import {
  useViewportScroll,
  motion,
  useTransform,
  useSpring,
} from 'framer-motion';
import { useState,useEffect,useRef } from "react";
import Item from "../components/Item";
import { ReactComponent as Icon1 } from "../images/down-icon.svg"

export default function HomeScreen() {
  const [wHeight, setWHeight] = useState(window.innerHeight + 150);
  const { scrollY } = useViewportScroll();
  const y2 = useTransform(scrollY, [0, wHeight], [0, wHeight / 2.5]);
  const y3 = useTransform(scrollY, [0, wHeight], [0, wHeight * -0.2]);

  const x1 = useSpring(y3, { stiffness: 5000, damping:100 })

  const o1 = useTransform(scrollY, [0, 50], [1,0]);
  const o2 = useTransform(scrollY, [wHeight /5, wHeight /2], [1,0]);

  const ref = useRef();
  const background = useTransform(scrollY, [0, 250], ["rgba(300,300,300)","rgba(50,50,50)"]);

  const Image = () => {
    return(
      <motion.div style={{y:x1 , opacity:2}} className="HomeScreen__image">
        <motion.div className="HomeScreen__image-image"/>
      </motion.div>
    )
  }

  const Header = () => {
    return(
      <motion.div style={{y:2, opacity:1}} className="HomeScreen__header-h1">
        <h1>PAVEL SUCHÁNEK</h1>
        <motion.h2 style={{marginTop:50,opacity:1}}>Front-end Developer</motion.h2>
      </motion.div>
    )
  }

  const Footer = () => {

    const Div = () => {
      const animation = {
        animate: {
          marginTop: 5,
          transition: {
            bounce: 0,
            duration: 0.5,
            repeat: Infinity,
            repeatType: "reverse",
          }
        }
      };

      return(
        <motion.div {...animation}>
          <Icon1 className="HomeScreen__icon"/>
        </motion.div>
      )
    }

    const Scroll = () => {
      ref.current.scrollIntoView({behavior: "smooth",  block: 'start',
      inline: 'nearest'});
    }

    return(
      <motion.div ref={ref} className="HomeScreen__footer">
        <div className="HomeScreen__footer-top">
          <Item onClick={Scroll} image={<Div/>}/>
        </div>
      </motion.div>
    )
  }

  return (
    <div>
      <div className="HomeScreen">
        <Image/>
        <div className="HomeScreen__container">
          <Header/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

