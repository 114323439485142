import './App.css';
import { useState,useEffect } from 'react';
import OnImagesLoaded from 'react-on-images-loaded';
import HomeScreen from './screens/HomeScreen.jsx'; 
import AboutScreen from './screens/AboutScreen.jsx'; 
import SkillsScreen from './screens/SkillsScreen.jsx';
import ContactScreen from "./screens/ContactScreen.jsx"
import FooterScreen from "./screens/FooterScreen.jsx"
import LoadScreen from "./screens/LoadScreen.jsx"

import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue,
  useSpring
} from 'framer-motion';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    },3000)
  })

  const Section = ({Screen}) => {
    return(
    <section>
      {Screen}
    </section>
    )
  }

  const Body = () => {

    const animation = {
      animate: {
        opacity: 1,
        transition: {
          duration: 1,
        }
      }
    };

    return(
      <motion.div {...animation} initial={{opacity:0}} >
        <Section Screen={<HomeScreen/>}/>
        <Section Screen={<AboutScreen/>}/>
        <Section Screen={<FooterScreen/>}/>
      </motion.div>
    )
  }



  //100% + 150px
  //100% + 100px
  //100%

  return (
    <div className="App">
      {loading ? <LoadScreen/> : <Body/>}
    </div>
  );
}

export default App;
